import React from "react";
import { DetectionResult } from "@core/types/detection/DetectionTypes";
import { useDetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { useDetectionSummary } from "~/screens/app/detection/data/useDetectionSummary";
import { useAllSummedSizeStats } from "~/screens/app/detection/data/sumBrandStats";
import { LogoBrandsTable2 } from "~/screens/pdf/dashboard/logo/LogoBrandsTable2";

interface LogoBrandsTableProps {
  results: DetectionResult[];
}

export function LogoBrandsTable({ results }: LogoBrandsTableProps) {
  const { filter } = useDetectionFilter();
  const { summed } = useDetectionSummary(results, filter);
  const summedByBrand = useAllSummedSizeStats(summed);

  return <LogoBrandsTable2 summed={summed} summedByBrand={summedByBrand} />;
}
