import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { faChartBar, faVideo } from "@fortawesome/free-solid-svg-icons";
import { LogoBrandsTable } from "~/screens/pdf/dashboard/logo/LogoBrandsTable";
import Widget from "~/components/widget/Widget";
import { formatDurationFull } from "~/utils/formatUtils";
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget";
import { useDetectionSummary } from "~/screens/app/detection/data/useDetectionSummary";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { StreamWithLatestResult } from "~/screens/pdf/dashboard/logo/useCurrentProjectTvDetection";
import { useDetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { TopBrandsWidget } from "~/screens/app/detection/stats/project/summary/TopBrandsWidget";
import { useBrandsInStats } from "~/screens/app/detection/utils/useBrandsInStats";

interface pdfTvLogosProps {
  results: StreamWithLatestResult[];
  title: string;
}

export function PdfTvLogos({ results, title }: pdfTvLogosProps) {
  const detections = useMemo(() => results?.map((r) => r.detection), [results]);
  const { filter } = useDetectionFilter();
  const { summed, totalLength } = useDetectionSummary(detections, filter);
  const { brands } = useBrandsInStats(summed);
  if (!results) return null;
  return (
    <>
      <PageTitle>
        {title} <Duration>[{formatDurationFull(totalLength)}]</Duration>
      </PageTitle>
      <Grid>
        {summed && brands && <TopBrandsWidget stats={summed} brands={brands} />}
        {/*<NumberWidget*/}
        {/*  title={"Total videos"}*/}
        {/*  subTitle={"total number of videos containing logos"}*/}
        {/*  icon={faVideo}*/}
        {/*  value={results.length}*/}
        {/*  subValue={<span>{formatDurationFull(totalLength)}</span>}*/}
        {/*/>*/}
        {/*<NumberWidget*/}
        {/*  title={"Total videos"}*/}
        {/*  subTitle={"total number of videos containing logos"}*/}
        {/*  icon={faVideo}*/}
        {/*  value={results.length}*/}
        {/*  subValue={<span>{formatDurationFull(totalLength)}</span>}*/}
        {/*/>*/}
      </Grid>
      <Widget title={"Brand statistics"} icon={faChartBar}>
        <LogoBrandsTable results={detections} />
      </Widget>
    </>
  );
}

const Duration = styled.span`
  color: #808080;
`;

const Grid = styled(WidgetGrid)`
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
`;

const PageTitle = styled.h1`
  font-size: 1.4em;
  margin-bottom: 12px;
  font-weight: bold;
`;
